import React, { useState, useEffect } from 'react'
import "./messageTemplates.css"
import { DataGrid} from '@mui/x-data-grid';

import Input from '../ReUsedComponents/inputField/Input'
// import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';
import TextArea from '../ReUsedComponents/textareaField/TextArea'
import SelectField from '../ReUsedComponents/selectField/SelectField'
import Loading from '../ReUsedComponents/loadingScreen/Loading';
// import DeleteModal from '../ReUsedComponents/deleteModal/DeleteModal'

import { getAllTemplates } from '../../apis/TemplatesAPI';
import { useDispatch } from 'react-redux';

// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const MessageTemplates = () => {

    const dispatch = useDispatch()

    // const [actionPending, setActionPending] = useState(false);

    const [broadcastModalState, setBroadcastModalState] = useState(false);
   
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [contact, setContact] = useState({ name: '', phoneNumber: ''});
    const [formErrors, setFormErrors] = useState()

    const [allTemplates, setAllTemplates] = useState([]);

    //TODO: Broadcast details
    const [mediaType, setMediaType] = useState('')
    const [mediaFile, setMediaFile] = useState('');
    // const [contactsFile, setContactsFile] = useState('');
    // const [groupQuery, setGroupQuery] = useState('')
    // const [contactQuery, setContactQuery] = useState('')
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([])
    const [groups, setGroups] = useState([])

    
    console.log(mediaType, mediaFile, name, message, setSelectedContacts, contact, setGroups)


    useEffect(() => {
      getAllTemplates(dispatch).then(resp => {
          if (resp?.status === 200) { setAllTemplates(resp?.data?.data); }
          else { console.log("Error occured...") }
        })
      
    }, [dispatch, pageSize, pageNo])

    console.log(allTemplates)


  const columns = [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false },
      { field: 'category', headerName: 'Category', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'status', headerName: 'Status', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false,
       renderCell: (param) => <span id={`status-${param.value}`}>{param.value}</span> },
      { field: 'language', headerName: 'Language', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'actions', headerName: 'Actions', width: 150, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => (
              <div className='contacts-table-actions'>
                <i class="pi pi-eye" id="view-action-icon" />
                <i class="pi pi-copy" id="view-action-icon"/>
                <i class="pi pi-trash" id="delete-action-icon" />
              </div>
          )
      },
  ];


//   const contactsColumns = [
//     { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false },
//     { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
// ];


  // const handleOnChange = (e) => {
  //   setContact({ ...contact, [e.target.name]: e.target.value })
  // }

  // const validate = () => {
  //   let errors = {}
  //   if (!contact?.name) {
  //     errors['name'] = 'Name is required!'
  //   }
  //   if (!contact?.phoneNumber) {
  //     errors['number'] = 'Invalid phone number.Eg 254123456789!'
  //   }
  //   setFormErrors(errors)
  //   return errors
  // };

  
  const handleCloseModal = () => {
    setContact({ name: '', phoneNumber: ''})
    setFormErrors([])
    document.getElementById("form-modal").reset();
    setBroadcastModalState(false)
    console.log(selectedContacts, groups)
  }


    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Template Messages</p>
                </div>
                <div className='table-toolbar'>
                  <input type='text' className="search-table-input" placeholder='Search table...' />
                  <div>
                  <button type='button' className='create-btn' onClick={() => setBroadcastModalState(true)}>New Template Message</button>
                  </div>
                </div>
                
            </div>
            <div>

        <div className='table-section'> 
           {allTemplates?.length === 0? <Loading /> : 
            <DataGrid rowHeight={40} rows={allTemplates?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allTemplates?.data?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',height: '500px'}} 
                    />}
            </div>
          </div>


  {/* ///// CREATING TEMPLATE  */}
 
  <div className={broadcastModalState ? 'broadcast-create-modal-active' : 'broadcast-create-modal-inactive'} >
        <form id="form-modal" className='broadcast-view-modal-content broadcast-form'>
          <p className='broadcast-modal-title'> New Template Message
            <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal(dispatch)} /></p>
          <div className="broadcast-dialog">

            <div className='broadcast-dialog-inputs'>
              <Input label="Template Name *" name='name' type='text' handleChange={(e) => setName(e.target.value)} errorMessage={formErrors?.name} />
              <Input label="Category *" name='name' type='text' handleChange={(e) => setName(e.target.value)} errorMessage={formErrors?.name} />
            </div>
            <div className='broadcast-dialog-inputs'>
             <SelectField name="mediaType" placeholder="Select Media Type" label="Media Type" selects={['IMAGE', 'AUDIO', 'DOCUMENT']}
                handleChange={(e) => setMediaType(e.target.value)} errorMessage={formErrors?.mediaType} style={{width: '250px',}}/>
              <Input label="Media File" name='mediaFile' type='file' handleChange={(e) => setMediaFile(e.target.files[0])} /> 
            </div>
            <TextArea label="Body *" name='message' type='text' handleChange={(e) => setMessage(e.target.value)} error={formErrors?.message} />
          
          </div>
          <div className='dialog-footer'>
            <button type='button' className="submit-btn">Save Template</button>
          </div>
        </form>
      </div>


    


        </div>
    )
}

export default MessageTemplates