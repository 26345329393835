import { createSlice } from "@reduxjs/toolkit";

export const WhatsAppSlice = createSlice({
    name: 'whatsapp',
    initialState: {
        whatsAppConnected: "false",
        qrCodeUrl: null,
        apiMessageForm: "false",
        showQRCodePage: "false",
    },
    reducers: {

        setWhatsAppConnected: (state, action) => {
            state.whatsAppConnected = action.payload
        },
        setQRCodeUrl: (state, action) => {
            state.qrCodeUrl = action.payload
        },
        showApiMessageForm: (state, action) => {
            state.apiMessageForm = action.payload
        },
        showScreenShot: (state, action) => {
            state.showQRCodePage = action.payload
        },  
    },

})

export const { setWhatsAppConnected, setQRCodeUrl, showApiMessageForm, showScreenShot } = WhatsAppSlice.actions
export default WhatsAppSlice.reducer