import { useEffect, useState } from 'react';
import { Client as StompClient } from '@stomp/stompjs';
import SockJS from 'sockjs-client'; 

const useWebSocket = (onMessageReceived) => {
  const [connected, setConnected] = useState(false);

  const showGreeting = (message) => {
    console.log("Greeting from:", message);
    // Add any additional logic you need here
  };

  useEffect(() => {
    const headers = {
      Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiYXJhd2Fqb3NodWE3QGdtYWlsLmNvbSIsImlhdCI6MTcyMjQyNDc2NSwiZXhwIjoxNzIyNDI4MzY1fQ.QGUeRraiRy4TItyhhVWkvdSEaIT9M8fu_LThp4RCgBA' // Add your token here
    };

    const socket = new SockJS('https://crmtest.lipachat.com/ws?username=barawajoshua7@gmail.com');
    const stompClient = new StompClient({
      webSocketFactory: () => socket,
      connectHeaders: headers,
      debug: (str) => console.log(str)
    });

    stompClient.onConnect = (frame) => {
      setConnected(true);
      console.log('Connected: ' + frame);
      stompClient.subscribe('/topic/messages', (message) => {
        console.log("Received new message " + message);
        showGreeting(JSON.parse(message.body).from);
      });
      stompClient.subscribe('/user/queue/messages', (message) => {
        console.log("Received new queue message " + message);
        // showGreeting(JSON.parse(message.body).from);
        if (onMessageReceived) {
          onMessageReceived(); // Call the callback function when a new queue message is received
        }
      });
    };

    stompClient.onWebSocketError = (error) => {
      console.error('Error with websocket', error);
    };

    stompClient.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers['message']);
      console.error('Additional details: ' + frame.body);
    };

    const connect = () => {
      stompClient.activate();
    };

    const disconnect = () => {
      stompClient.deactivate();
      setConnected(false);
      console.log("Disconnected");
    };

    connect();

    return () => {
      disconnect();
    };
  }, [onMessageReceived]);

  return { connected };
};

export default useWebSocket;
