import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux/ActionSlice";

export const updateUserPassword = async (dispatch, user) => {
    dispatch(actionStart())
    try {
        const resp = axios.put(process.env.REACT_APP_BASE_URL + '/user/password', user, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}