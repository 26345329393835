import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import './forgotpass.css'

import { sendResetPasswordEmail } from '../../apis/PasswordResetAPI';
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const dispatch = useDispatch()

    const loginPending = useSelector((state) => state.action)
    const [sendIntructions, setSendIntructions] = useState(false)
    const [user, setUser] = useState({username: ''})
    const [formErrors, setFormErrors] = useState()
    let navigate = useNavigate()

    const handleOnChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
      }

    const sendResetEmail = () => {
        if (Object.keys(validate()).length === 0) {
            sendResetPasswordEmail(dispatch, user).then(resp => {
                if (resp?.data?.error === true) {
                    toast.error("User email does not exists!");
                } else{ setSendIntructions(true)  } 
            })
        }
    }

    const loading = () => {
        return <div class="lds-dual-ring"></div>
    }


    //Handling form validation
    const validate = () => {
        let errors = {}
        if (!user.username) {
            errors['username'] = 'Email address is required!'
        }
        setFormErrors(errors)
        return errors
    };



    return (
        <div className='forgot-password-page'>
           
            <div className='forgot-password-section'>
               
                <div className='forgot-pass-form-sec'>
                    <div className='forgot-password-form'>
                        <p className='forgot-password-form-header'>Forgot Password</p>
                        <p className='forgot-password-p'>Enter Email associated with your account and we'll send an
                            email with reset link</p>

                        <div className='input-group'>
                            <label className='forgot-password-label'>Email Address</label>
                            <input id="myInput" type="text" name='username' className='forgot-password-input' 
                              placeholder='email@gmail.com' onChange={(e) => handleOnChange(e)} />
                            <span id='form-error'>{formErrors?.username}</span>
                        </div>

                        <div>
                            {!loginPending.pending ?
                                <button type='button' className="forgot-password-button" onClick={sendResetEmail}>Send</button> :
                                <button type='button' className="forgot-password-disable" disabled="disabled">{loading()}</button>
                            }
                        </div>
                        <NavLink to="/auth/login" className="go-to-login">Back to login</NavLink>
                    </div>
                </div>

            </div>

            <div className={sendIntructions ? 'email-modal-active': 'email-modal-inactive'}>
            <div className="delete-modal" >
                <img id="delete-img" src={process.env.PUBLIC_URL + "/images/mail_sent.svg"} width="60" height="70" alt='email-sent' />
                <p className='email-message'>We have sent password reset instructions to your email.</p>
                <div className='a-buttons'>
                    <button type='submit' className="dismiss-button" onClick={() => {setSendIntructions(false);navigate('/auth/login'); window.location.reload()}}>Close</button>
                </div>

            </div>
        </div>

        <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


        </div>
    );
}

export default ForgotPassword