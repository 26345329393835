import React, { useState, useEffect, useRef } from 'react';
import './chatsection.css';
import SessionTimer from '../../ReUsedComponents/sessionTimer/SessionTimer';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

const ChatSection = ({ allAgents, messagesData, handleChatMessageChange, sendChatMessage, chatMessage, changeConvoStatus, assingAgent, sessionInfo, 
  sessionExpired, keyDownCreateNewLine }) => {

  const selector = useSelector((state) => state.session);

  const [sessionExpiration, setSessionExpiration] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(sessionInfo?.assignedUser?.id);
  const [selectedStatus, setSelectedStatus] = useState(sessionInfo?.sessionStatus);
  const messagesEndRef = useRef(null);

  const chatStatusList = [
    {title: "Open", value: "OPEN"},
    {title: "Pending", value: "PENDING"},
    {title: "Solved", value: "SOLVED"},
    {title: "Expired", value: "EXPIRED"},
  ];

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (sessionInfo?.expiresAt) {
      const expirationTime = new Date(sessionInfo.expiresAt).getTime();
      setSessionExpiration(expirationTime);

      const checkExpiration = () => {
        // const now = new Date().getTime();
      };
      checkExpiration();
      const interval = setInterval(checkExpiration, 1000);
      return () => clearInterval(interval);
    }
  }, [sessionInfo]);


  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 1000);
    return () => clearTimeout(timeoutId);
  }, [messagesData]);


  useEffect((e) => {
    setSelectedAgent(sessionInfo?.assignedUser?.id);
    setSelectedStatus(sessionInfo?.sessionStatus);
  }, [sessionInfo]);

  const handleAgentChange = (e) => {
    setSelectedAgent(e.target.value);
    assingAgent(e);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    changeConvoStatus(e);
  };

  const handleClearMessage = () =>{
    document.getElementById('messageInput').value = ''
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendChatMessage();
      document.getElementById('messageInput').value = '';
    }
  };

  return (
    <div className='inbox-chat-section'>
      <div style={{ height: '80vh' }}>
        <div className='actions-chat-section'>

          <div className='active-agent-actions'>
            <div className='session-timer-div'>
              <p className='agent-status-info'>Chat expires in</p>
              <SessionTimer sessionExpiration={sessionExpiration} />
            </div>
            <div className='active-agent-status'>
              <p className='agent-status-info'>Assign Agent</p>
              <select
                type='text'
                className='agent-actions-select-field'
                value={selectedAgent}
                onChange={handleAgentChange}
                disabled={sessionExpired}
              >
                <option id='option' value={sessionInfo?.assignedUser?.id} selected>
                  {sessionInfo?.assignedUser?.name}
                </option>
                {allAgents
                  ?.filter((e) => e.name !== sessionInfo?.assignedUser?.name)
                  .map((agentOption) => (
                    <option id='option' value={agentOption.id} key={agentOption.id}>
                      {agentOption?.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className='agent-ticket-status-section'>
            <p className='agent-status-info'>Chat Status</p>
            <select
              type='text'
              className='agent-actions-select-field'
              onChange={handleStatusChange}
              value={selectedStatus}
              disabled={sessionExpired}
              style={{ textTransform: 'capitalize', backgroundColor: 'white', overflowY: "scroll" }}
            >
             {chatStatusList.map(status => 
               <option id='option' value={status.value} key={status.title}>{status?.title} </option>)}
            </select>
          </div>
        </div>
        <div className='messages-section'>
          {messagesData.map((message, index) => (
            <div key={index} className={message.messageSource === 'OUTBOUND' ? 'outgoing-message-sec' : 'incoming-message-sec'}>
              <div className={message.messageSource === 'OUTBOUND' ? 'outgoing-message' : 'incoming-message'}>
                {message?.messageType === 'IMAGE' ? (
                  <img src={message?.mediaUrl} className='message-content image-content' alt='' />
                ) : message?.messageType === 'LIST' ? (
                  message?.text.split('\n').map((msg, idx) => (
                    <p className='message-content' key={idx}>
                      {msg}
                    </p>
                  ))
                ) : (
                  <p className='message-content'>{message?.text}</p>
                )}
                <p className='message-datetime'>{dateFormat(message?.createdAt, 'dd/mm/yyyy HH:MM')}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className='chatbox-input'>
        {sessionExpired? 
        <div>
          <div style={{display: "flex", justifyContent:'space-between', alignItems: 'flex-end'}}>
             <p style={{fontSize: '20px', fontWeight: 'bold'}}>This chat is resolved and expired</p>
             <button className='select-temp-btn'>Select Template</button>
          </div>
          <p style={{fontSize: '14px', fontWeight: '500'}}>Chats are marked as expired 24 hours after the last received customer message.</p>
        </div> :

          <div className='chatbox-input'>
          {selector?.user?.id === selectedAgent ? 
             
             <div className='chatbox-input'>
             <input type='text' id="messageInput" name='message' placeholder='Type a message here' 
             onChange={handleChatMessageChange} onKeyPress={handleKeyPress} onKeyDown={keyDownCreateNewLine}
             disabled={sessionExpired}
             />
             {chatMessage?.message  && (
               <button type='submit' className='send-message-button' onClick={() => {sendChatMessage(); handleClearMessage()}}>
                 Send
               </button>
             )}
             </div>
             :<div>
                <p style={{fontSize: '20px', fontWeight: 'bold'}}>You are not assigned to this chat!</p>
                <p style={{fontSize: '14px', fontWeight: '500'}}>Assign yourself to get started</p>
              </div>
         }
          </div>
      }
      </div>
    </div>
  );
};

export default ChatSection;
