import React, { useState, useEffect } from 'react';
import './sessiontimer.css';

const SessionTimer = ({ sessionExpiration }) => {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0, distance: 0 });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = sessionExpiration - now;

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      return {
        hours,
        minutes,
        seconds,
        distance,
      };
    };

    const timerId = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft.distance <= 0) {
        clearInterval(timerId);
      }
      setTime(newTimeLeft);
    }, 1000);

    return () => clearInterval(timerId);
  }, [sessionExpiration]);

  const formatTime = (value) => {
    return value.toString().padStart(2, '0');
  };

  return (
    <div>
      {time.distance > 0 ? (
        <div className="countdown-timer">
          <span>{formatTime(time.hours)}:</span>
          <span>{formatTime(time.minutes)}:</span>
          <span>{formatTime(time.seconds)}</span>
        </div >
      ) : (
        <p className='session-expired'>Session Expired</p>
      )}
      
    </div>
  );
};

export default SessionTimer;
