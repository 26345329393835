import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import './resetpassword.css'

import { getUserDetails, resetUserPassword } from '../../apis/PasswordResetAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {

    const dispatch = useDispatch()
    const actionPending = useSelector((state) => state.action)
    const [resetSuccessful, setResetSuccessful] = useState(false)

    const { token } = useParams();
    const [user, setUser] = useState();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')

    const [formErrors, setFormErrors] = useState()
    let navigate = useNavigate()

    useEffect(() => {
        getUserDetails(dispatch, token).then(resp => {
            if (resp?.status === 200) {
            setUser({username:resp?.data?.user?.username, password:newPassword, token: token})
            }
            
        })
    }, [dispatch, token, newPassword])
   
    const loading = () => {
        return <div class="lds-dual-ring"></div>
    }

    //Handling form validation
    const validate = () => {
        let errors = {}
        if (!newPassword) {
            errors['newPassword'] = 'New password is required!'
        }

        if (!confirmPassword) {
            errors['confirmPassword'] = "Confirm password is required!"
        }
        else if (confirmPassword !== newPassword) {
            errors['confirmPassword'] = "New and confirm password don't match!"
        }
        setFormErrors(errors)
        return errors
    };


    const handleResetPassword = () => {
        if (Object.keys(validate()).length === 0) {
            resetUserPassword(dispatch, user).then(resp => {
                if (resp?.data?.error === true) {
                    toast.error(resp?.data?.message);
                } else{ setResetSuccessful(true)  } 
            })
        }
    }

    const handleViewPassword = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }


    return (
        <div className='reset-password-page'>
           

            <div className='reset-password-section'>
                {/* <img src={process.env.PUBLIC_URL + "/images/forgot_password.svg"} alt="forgot-password-svg" className="reset-pass-svg" /> */}

                <div className='reset-pass-form-sec'>
                    <div className='reset-password-form'>
                        <p className='reset-password-form-header'>Create New Password</p>
                        <p className='reset-password-p'>Your new password must be different from the previous used passwords</p>

                        <div className='input-group'>
                            <label className='reset-password-label'>New Password *</label>
                            <input id="myInput" type="password" name='password' className='reset-password-input' onChange={(e) => setNewPassword(e.target.value)} />
                            <span id='form-error'>{formErrors?.newPassword}</span>
                        </div>

                        <div className='input-group'>
                            <label className='reset-password-label'>Confirm Password *</label>
                            <input id="myInput2" type="password" name='password' className='reset-password-input' onChange={(e) => setConfirmPassword(e.target.value)} />
                            <span id='form-error'>{formErrors?.confirmPassword}</span>
                        </div>
                        <p id="show-password">
                            <p><input type="checkbox" onChange={handleViewPassword} /><span> Show password</span></p>
                        </p>
                        <div>
                            {!actionPending.pending ?
                                <button type='button' className="reset-password-button" onClick={handleResetPassword}>Reset Password</button> :
                                <button type='button' className="reset-password-disable" disabled="disabled">{loading()}</button>
                            }
                        </div>
                       <NavLink to="/auth/login" className="login-link">Login</NavLink>
                    </div>
                   
                </div>

            </div>

            <div className={resetSuccessful ? 'reset-modal-active' : 'reset-modal-inactive'}>
                <div className="reset-modal" >
                    <img id="reset-img" src={process.env.PUBLIC_URL + "/images/mail_sent.svg"} width="60" height="70" alt='email-sent' />
                    <p className='reset-message'>Your password has been reset successfull. You can login with your new password</p>
                    <div className='a-buttons'>
                        <button type='submit' className="dismiss-button" onClick={() => { setResetSuccessful(false); 
                           navigate('/auth/login'); window.location.reload() }}>Close</button>
                    </div>

                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    );
}

export default ResetPassword