import React, { useState, useEffect } from 'react'
import "./broadcast.css"
import { DataGrid} from '@mui/x-data-grid';

import Input from '../ReUsedComponents/inputField/Input'
// import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';
import TextArea from '../ReUsedComponents/textareaField/TextArea'
import SelectField from '../ReUsedComponents/selectField/SelectField'
import Loading from '../ReUsedComponents/loadingScreen/Loading';
// import DeleteModal from '../ReUsedComponents/deleteModal/DeleteModal'

import { getAllContacts } from '../../apis/ContactsAPI';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';


// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';



const Broadcast = () => {

    const dispatch = useDispatch()

    // const [actionPending, setActionPending] = useState(false);

    const [broadcastModalState, setBroadcastModalState] = useState(false);
   
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [contact, setContact] = useState({ name: '', phoneNumber: ''});
    const [formErrors, setFormErrors] = useState()

    const [allContacts, setAllContacts] = useState([]);

    //TODO: Broadcast details
    const [mediaType, setMediaType] = useState('')
    const [mediaFile, setMediaFile] = useState('');
    // const [contactsFile, setContactsFile] = useState('');
    const [groupQuery, setGroupQuery] = useState('')
    const [contactQuery, setContactQuery] = useState('')
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([])
    const [groups, setGroups] = useState([])


    useEffect(() => {
        getAllContacts(dispatch, pageNo, pageSize).then(resp => {
          if (resp?.status === 200) { setAllContacts(resp?.data); }
          else { console.log("Error occured...") }
        })
      
    }, [dispatch, pageSize, pageNo])


  const columns = [
      { field: 'name', headerName: 'Broadcast Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false },
      { field: 'phoneNumber', headerName: 'Contacts', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'delivered', headerName: 'Delivered', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'failed', headerName: 'Failed', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'status', headerName: 'Status', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'updatedAt', headerName: 'Created By', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{dateFormat(param.row.updatedAt, "dd/mm/yyyy HH:MM")}</span> },
      { field: 'createdAt', headerName: 'Created At', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{dateFormat(param.row.createdAt, "dd/mm/yyyy HH:MM")}</span>},
      // { field: 'actions',
      //     headerName: 'Actions',
      //     width: 150,
      //     headerClassName: 'custom-table-header',
      //     sortable: false,
      //     renderCell: (param) => (
      //         <div className='contacts-table-actions'>
      //           <i class="pi pi-eye" id="view-action-icon" onClick={() => { setContact({ id: param.row?.id, name: param?.row?.name, phoneNumber: param?.row?.phoneNumber});}}/>
      //         </div>
      //     )
      // },
  ];


  const contactsColumns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
];


  // const handleOnChange = (e) => {
  //   setContact({ ...contact, [e.target.name]: e.target.value })
  // }

  // const validate = () => {
  //   let errors = {}
  //   if (!contact?.name) {
  //     errors['name'] = 'Name is required!'
  //   }
  //   if (!contact?.phoneNumber) {
  //     errors['number'] = 'Invalid phone number.Eg 254123456789!'
  //   }
  //   setFormErrors(errors)
  //   return errors
  // };

  
  const handleCloseModal = () => {
    setContact({ name: '', phoneNumber: ''})
    setFormErrors([])
    document.getElementById("form-modal").reset();
    setBroadcastModalState(false)
  }


  console.log(contact, mediaType,mediaFile, name, message, setSelectedContacts, setGroups)

    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Broadcasts</p>
                </div>
                <div className='table-toolbar'>
                  <input type='text' className="search-table-input" placeholder='Search table...' />
                  <button type='button' className='create-btn' onClick={() => setBroadcastModalState(true)}>New Broadcast</button>
                </div>
                
            </div>
            <div>


        <div className='table-section'> 
           {allContacts?.length === 0? <Loading /> : 
            <DataGrid rowHeight={40} rows={allContacts?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allContacts?.data?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',}} 
                    autoHeight/>}
            </div>
          
            </div>


  {/* /////////////////////CREATING CONTACT //////////// */}
 
  <div className={broadcastModalState ? 'broadcast-create-modal-active' : 'broadcast-create-modal-inactive'} >
        <form id="form-modal" className='broadcast-view-modal-content broadcast-form'>
          <p className='broadcast-modal-title'> New Broadcast
            <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal(dispatch)} /></p>
          <div className="broadcast-dialog">
            <Input label="Name *" name='name' type='text' handleChange={(e) => setName(e.target.value)} errorMessage={formErrors?.name} />
            <TextArea label="Message *" name='message' type='text' handleChange={(e) => setMessage(e.target.value)} error={formErrors?.message} />
            <div className='broadcast-dialog-inputs'>
             <SelectField name="mediaType" placeholder="Select Media Type" label="Media Type" selects={['IMAGE', 'AUDIO', 'DOCUMENT']}
                handleChange={(e) => setMediaType(e.target.value)} errorMessage={formErrors?.mediaType} style={{width: '250px',}}/>
              <Input label="Media File" name='mediaFile' type='file' handleChange={(e) => setMediaFile(e.target.files[0])} /> 
            </div>
            {/* Contacts File Upload Dialog */}
            <div className='broadcast-contacts-input-section'>
              
              <div>

                <div className='broadcast-impor-sec'>
                    <p className='form-label'>Select contacts below  <strong>OR</strong><span> <a className="template-link" href={process.env.PUBLIC_URL + "/sample_contact_file.xlsx"}>
                    Download sample format for contact upload</a></span></p>
                    <p id='error'>{formErrors?.contacts}</p>

                   <div class="upload-btn-wrapper">
                        <button class="btn">Upload contacts</button>
                        <input type="file" name="myfile" />
                    </div>
                </div>


                <div className='broadcast-contacts-inputs' >
                  <div className='table-toolbar'>

                    <select type='text' className='broadcast-filter-group' value={groupQuery} onChange={(e) => { setContactQuery(''); setGroupQuery(e.target.value) }}>
                      <option id='option' value="" selected disabled hidden>Filter by group </option>
                      {groups.map((group, id) =>
                        <option id='option' key={id} style={{ fontSize: '14px' }} value={group.name.toLowerCase()}>{group.name}</option>
                      )}
                    </select>

                    <input type='text' className="broadcast-table-search" placeholder='search' value={contactQuery} onChange={(e) => { setGroupQuery(''); setContactQuery(e.target.value) }} />
                  </div>
                  <span style={{ fontSize: '13px', fontWeight: 600 }}>Selected Contacts: {selectedContacts.length}</span>
                  {allContacts?.length === 0? <Loading /> : 
                   <DataGrid rowHeight={40} rows={allContacts?.data} columns={contactsColumns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allContacts?.data?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',}} 
                    autoHeight/>}
                </div>
              </div>
            </div>

          </div>
          <div className='dialog-footer'>
            <button type='button' className="submit-btn">Send Broadcast</button>
          </div>
        </form>
      </div>


    


        </div>
    )
}

export default Broadcast